import type { Translation } from '../i18n-types';

const zh: Translation = {
  Tags: '標籤',
  FilterTags: '過濾標籤...',
  TableOfContent: '目錄',
  LoadingPosts: '讀取文章列表中...',
  LoadingPost: '讀取文章中...',
  NoPostFound: '找不到文章。',
  LoadingGiscus: '讀取 Giscus 評論中...',
  FirstPublishedAt: '最初發表於',
  LastUpdatedAt: '最後更新於',
  Updated: '更新於',
  JustNow: '剛剛',
  MinuteAgo: '{0} 分鐘前',
  HourAgo: '{0} 小時前',
  DayAgo: '{0} 天前',
  MonthAgo: '{0} 個月前',
  YearAgo: '{0} 年前',
  Page404NotFound: 'Page Not Found',
  Page404BackHome: '趕緊回首頁 !',
  IndexSearchBox: '搜尋',
  IndexCloseSearchBox: '關閉',
};

export default zh;
