<script lang="ts">
  import { siteConfig } from '$config/site';
  import { fly } from 'svelte/transition';
  import { postsShow } from '$stores/posts';
  import tippy from '$lib/actions/tippy';
  import { onMount } from 'svelte';
  import { LL } from '$i18n/i18n-svelte';

  let className: string | undefined = undefined;
  export { className as class };

  const sinceYear = siteConfig.since ? siteConfig.since.toString() : undefined;
  const curYear = new Date().toJSON().substring(0, 4);
  const copyrightYear = `${sinceYear && sinceYear !== curYear ? `${sinceYear}-${curYear}` : `${curYear}`}`;

  let loaded = false;
  onMount(() => {
    loaded = true;
  });
</script>

{#if loaded}
  {#key $postsShow}
    <footer
      id="footer"
      in:fly|global={{ y: 100, duration: 1200, delay: 900 }}
      out:fly|global={{ y: 100, duration: 300 }}
      class="flex flex-col justify-center items-center flex-none w-full mx-auto xl:mx-0 {className ?? ''}">
      <main class="rounded-b-2xl pb6 my4 gap1 flex flex-col justify-center items-center md:(border-t-2 w-3xl mt-8)">
        <div class="flex justify-center mt-4">
          <a use:tippy href="/atom.xml" class="btn btn-ghost" aria-label="Atom Feed" data-sveltekit-preload-data="off">
            <div class="!w-[1.75rem] !h-[1.75rem] i-ic-baseline-rss-feed" />
          </a>
          <a use:tippy href="/sitemap.xml" class="btn btn-ghost" aria-label="Sitemap" data-sveltekit-preload-data="off">
            <div class="!w-[1.75rem] !h-[1.75rem] i-mdi-sitemap-outline" />
          </a>
        </div>
        <div class="copyright-notice flex flex-col items-center">
          <p itemprop="copyrightNotice">
            © <span itemprop="copyrightYear">{copyrightYear}</span>
            <a href={siteConfig.author.website} rel="external author noopener" target="_blank">
              <span itemprop="copyrightHolder">{siteConfig.author.name}</span>
            </a>
          </p>
          <p>
            <a href="/legal" aria-label="copyright and licensing information" rel="license noopener">
              Content licensed under <span itemprop="license">CC BY-NC 4.0</span>
            </a>
          </p>
        </div>
      </main>
    </footer>
  {/key}
{/if}

<style lang="scss">
  #footer main {
    background-color: var(--qwer-bg-color);
    color: var(--qwer-text-color);
    border-top-color: var(--qwer-border-top-color);
  }
  #footer a:not(.btn) {
    &:hover {
      color: var(--qwer-link-hover-color);
    }
  }
</style>
